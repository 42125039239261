import React from 'react'
import { motion } from 'framer-motion'

import { BigText } from '@/components/BigText.tsx'
import { cn } from '@/lib/utils.ts'
import { SelectionButton } from '@/pages/CheckIn/SelectionButton.tsx'
import { LocationGroup, MicroService } from '@/pages/CheckIn/types.ts'

const MicroServicesList: React.FC<{ microServices: Array<MicroService> }> = ({
  microServices,
}) => {
  return (
    <div className="whitespace-normal text-left text-lg uppercase md:text-xl lg:text-xl xl:text-4xl 2xl:text-5xl">
      <div
        className={cn(
          'columns-1 gap-8',
          microServices?.length > 6 && '!columns-2',
          microServices?.length > 12 && '!columns-3'
        )}
      >
        {microServices?.map((microService: MicroService) => {
          // const isLast = index === microServices.length! - 1

          return (
            <div
              key={microService.name}
              className={cn(microService?.items?.length && 'mr-2')}
            >
              {microService.name}

              {/*<MicroServiceItems items={microService.items} showParens />*/}
              {/*{!isLast && ' - '}*/}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const ChooseMacroGroup: React.FC<{
  locationGroups: Array<LocationGroup>
  onSelection: (groupIndex: number) => void
}> = ({ locationGroups, onSelection }) => (
  <motion.div
    key="chooseMacroGroup"
    className="flex grow flex-col"
    initial={{ opacity: 0, translateY: '-1%' }}
    animate={{ opacity: 1, translateY: 0 }}
    exit={{ opacity: 0, translateY: '1%' }}
    transition={{ duration: 0.3 }}
  >
    <BigText className="mb-16 text-justify">
      Buongiorno. <br />
      Prego selezionare il servizio
    </BigText>

    <div className="flex grow flex-col">
      <div className="flex grow flex-col space-y-12">
        {locationGroups.map((group, groupIndex) => (
          <SelectionButton
            key={group.name}
            className="border px-6 py-4 text-black"
            style={{ background: group.locations?.[0].color as string }}
            onClick={() => onSelection(groupIndex)}
          >
            <div className="font-bold uppercase">{group!.name}</div>
            {!!group?.microServices?.length && (
              <MicroServicesList microServices={group?.microServices} />
            )}
          </SelectionButton>
        ))}
      </div>
    </div>
  </motion.div>
)
