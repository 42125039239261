import React from 'react'
import * as Sentry from '@sentry/react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { ErrorBoundaryElement } from '@/components/ErrorBoundaryElement.tsx'
import { TailwindIndicator } from '@/components/TailwindIndicator'
import { queryCache, ReactQueryCacheProvider } from '@/hooks/queryHooks'
import { useIdentity } from '@/hooks/useIdentity.ts'
import { AblyProvider } from '@/lib/ably'
import { CheckIn } from '@/pages/CheckIn/CheckIn'
import { Monitor } from '@/pages/Monitor/Monitor'
import { Redirector } from '@/pages/Redirector'
import { Root } from '@/pages/Root'

import './App.css'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorBoundaryElement />,
    children: [
      /* {
        path: '/session/:id/welcome',
        element: (
          <ProtectedRoute>
            <Welcome />
          </ProtectedRoute>
        ),
      },
      {
        path: '/session/:id/payment',
        element: (
          <ProtectedRoute>
            <Session />
          </ProtectedRoute>
        ),
      },
      ,*/
      {
        path: '/checkin-kiosk/:checkinKioskId',
        element: <CheckIn />,
      },
      {
        path: '/monitor/:monitorId',
        element: <Monitor />,
      },
      {
        path: '/redirector',
        element: <Redirector />,
      },
    ],
  },
])

const AblyWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const me = useIdentity()

  return (
    <AblyProvider isAuthenticated={!!me?.data?.identity?.id}>
      {children}
    </AblyProvider>
  )
}

function App() {
  return (
    <>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <AblyWrapper>
          <RouterProvider router={router} />
        </AblyWrapper>
      </ReactQueryCacheProvider>

      <TailwindIndicator />
    </>
  )
}

export default App
