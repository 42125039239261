import React from 'react'

import './Arrow.css'

export const Arrow: React.FC<{ direction: 'left' | 'right' }> = ({
  direction,
}) => (
  <>
    {direction === 'left' && (
      <div className="arrow arrow--left">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M9 2.38l-7 6 7 6V2.38z" />
        </svg>
      </div>
    )}

    {direction === 'right' && (
      <div className="arrow arrow--right">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M7 2.38l7 6-7 6V2.38z" />
        </svg>
      </div>
    )}
  </>
)
