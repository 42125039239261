import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useIdentity } from '@/hooks/useIdentity.ts'
import { useToken } from '@/hooks/useToken.ts'

export const Root = () => {
  const navigate = useNavigate()
  const location = useLocation()
  useToken({
    locationSearch: location?.search,
    remove: false,
    registerMiddleware: true,
  })
  useIdentity()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(
        {
          pathname: `/redirector`,
          search: location.search,
        },
        { replace: true }
      )
    }
  }, [location.pathname, location.search, navigate])

  return (
    <div className="relative flex min-h-screen w-screen overflow-hidden">
      <div className="absolute inset-x-0 top-0 z-0 overflow-hidden pl-[50%]">
        <img
          src="/images/beams-home@95.jpeg"
          alt=""
          className="ml-[-39rem] w-[113.125rem] max-w-none"
        />
      </div>
      <main className="relative z-10 flex-1">
        <Outlet />
      </main>
    </div>
  )
}
