import React from 'react'
import { motion } from 'framer-motion'
import { ChevronLeft } from 'lucide-react'

import { BigText } from '@/components/BigText.tsx'
import { Button } from '@/components/ui/button.tsx'
import { useMutation } from '@/hooks/queryHooks.ts'
import { LocationGroup } from '@/pages/CheckIn/types.ts'

export const PrintingTicket: React.FC<{
  selectedLocationGroup?: LocationGroup | null
  enqueueMutation: ReturnType<typeof useMutation>
  onPress: () => void
}> = ({ enqueueMutation, selectedLocationGroup, onPress }) => {
  const enqueueResult = enqueueMutation.result?.data?.enqueue

  const handlePress = () => {
    if (enqueueMutation.result?.loading) return

    onPress()
  }

  return (
    <motion.div
      key="printingTicket"
      className="flex grow flex-col rounded"
      initial={{ opacity: 0, translateY: '-1%' }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: '1%' }}
      transition={{ duration: 0.3 }}
      style={{
        ...(!enqueueMutation.result?.loading &&
          !enqueueMutation.result?.errors && {
            background: selectedLocationGroup?.locations?.[0]?.color as string,
          }),
      }}
    >
      {enqueueMutation.result?.loading && (
        <div
          className="flex grow flex-col items-center justify-center"
          onClick={handlePress}
        >
          <BigText className="mb-8 space-y-32 text-center uppercase">
            Giusto un attimo...
          </BigText>
        </div>
      )}
      {!enqueueMutation.result?.loading && !enqueueMutation.result?.errors && (
        <div
          className="flex grow flex-col items-center justify-center"
          onClick={handlePress}
        >
          <BigText className="mb-8 grow space-y-32 p-20 text-center uppercase">
            <div>Lei è il numero</div>
            <div className="inline-flex aspect-square items-center justify-center rounded-full bg-white	p-8 align-middle text-4xl md:text-6xl lg:text-9xl">
              {enqueueResult?.location?.shortName} {enqueueResult?.ticketCode}
            </div>
            <div>
              Ritiri il biglietto e <br />
              proceda verso la sala d'attesa seguendo le indicazioni
              <br /> con la scritta
            </div>
            <div className="inline-flex aspect-square min-w-[14rem] items-center justify-center rounded-full bg-white	p-8 align-middle text-4xl md:text-6xl lg:text-9xl">
              {enqueueResult?.location?.shortName}
            </div>
          </BigText>

          <Button
            className="mb-10 h-auto shrink bg-gradient-to-r from-orange-500 to-red-500 px-4 py-6 text-xl hover:bg-indigo-500 hover:from-red-700 hover:to-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl"
            onClick={onPress}
          >
            <ChevronLeft className="mr-1" size={36} /> Nuovo Check-in
          </Button>
        </div>
      )}

      {!enqueueMutation.result?.loading && enqueueMutation.result?.errors && (
        <BigText></BigText>
      )}
    </motion.div>
  )
}
