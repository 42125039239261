import React, { useMemo } from 'react'
import { useNow } from '@gaudia/ui-common'
import dayjs from 'dayjs'

import { useAbly } from '../lib/ably'

export const OfflineWarning: React.FC = () => {
  const { stateChangeHistory } = useAbly()
  const mountedDate = useMemo(() => new Date(), [])
  const now = useNow()
  const elapsedSeconds = useMemo(
    () => dayjs(now).diff(mountedDate, 'seconds'),
    [mountedDate, now]
  )

  if (stateChangeHistory[0] === 'connected' || elapsedSeconds < 5) return null

  return (
    <div className="text-2vw fixed bottom-0 left-0 z-50 w-full bg-red-500 px-1 py-2.5 text-center font-bold text-white">
      Il dispositivo non risulta collegato ad internet
    </div>
  )
}
