import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import minBy from 'lodash/minBy'
import uniqueId from 'lodash/uniqueId'
import { create } from 'zustand'

interface FitTextProps {
  maxFontSize?: number
  minFontSize?: number
  text?: string | null
  id?: string
  className?: string
}

const increaseStep = 5

export const FitText: FC<FitTextProps> = ({
  maxFontSize: max,
  minFontSize: min,
  text,
  id,
  className,
}) => {
  const maxFontSize = max ?? 600
  const minFontSize = min ?? 1
  const [fontSize, setFontSize] = useState<number>(minFontSize)
  const textRef = useRef<HTMLDivElement>(null)
  const { sharedMaxFontSize, updateFontSize } = useSharedMaxFontSize(id)

  const adjustFontSize = async () => {
    updateFontSize({ isReady: false })
    if (textRef.current) {
      await document.fonts.ready

      const parent = textRef.current?.parentNode as HTMLElement
      let currentFontSize = minFontSize
      ////

      const { paddingTop, paddingBottom, paddingLeft, paddingRight } =
        extractComputedPaddingValues(getComputedStyle(parent))
      const paddingH = paddingRight + paddingLeft
      const paddingV = paddingTop + paddingBottom

      while (currentFontSize < maxFontSize) {
        textRef.current.style.fontSize = `${currentFontSize}px`
        if (
          textRef.current.scrollWidth > parent.offsetWidth - paddingH ||
          textRef.current.scrollHeight > parent.offsetHeight - paddingV
        ) {
          currentFontSize -= increaseStep
          break
        }
        currentFontSize += increaseStep
      }

      setFontSize(currentFontSize)
      if (id) {
        updateFontSize({ fontSize: currentFontSize, isReady: true })
      }
    }
  }

  useEffect(() => {
    adjustFontSize()
  }, [maxFontSize, minFontSize, text, id])

  useEffect(() => {
    const handleResize = () => adjustFontSize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [maxFontSize, minFontSize])

  const finalFontSize = id
    ? Math.min(fontSize, sharedMaxFontSize ?? Infinity)
    : fontSize

  return (
    <div
      ref={textRef}
      className={className}
      style={{ fontSize: `${finalFontSize}px`, whiteSpace: 'nowrap' }}
    >
      {text}
    </div>
  )
}

interface FitTextState {
  sharedFontSizes: { [layoutId: string]: Array<IndividualFitTextData> }
  updateFontSize: (
    layoutId: string,
    data: Partial<IndividualFitTextData> & { componentId: string }
  ) => void
  removeItem: (
    layoutId: string,
    data: Partial<IndividualFitTextData> & { componentId: string }
  ) => void
}

type IndividualFitTextData = {
  componentId: string
  isReady: boolean
  fontSize: number
}

export const useFitTextStore = create<FitTextState>((set) => ({
  sharedFontSizes: {},
  updateFontSize: (layoutId, data) =>
    set((state) => ({
      sharedFontSizes: {
        ...state.sharedFontSizes,
        [layoutId]: upsertItem(state.sharedFontSizes[layoutId] ?? [], data),
      },
    })),
  removeItem: (layoutId, data) =>
    set((state) => ({
      sharedFontSizes: {
        ...state.sharedFontSizes,
        [layoutId]: removeItem(state.sharedFontSizes[layoutId] ?? [], data),
      },
    })),
}))

// @ts-ignore
window.useFitTextStore = useFitTextStore

const useSharedMaxFontSize = (layoutId?: string) => {
  const { sharedFontSizes, updateFontSize, removeItem } = useFitTextStore()
  const componentId = useMemo(() => uniqueId(), [])
  const fontSizes = useMemo(
    () => (layoutId ? sharedFontSizes[layoutId] ?? [] : []),
    [layoutId, sharedFontSizes]
  )

  useEffect(() => {
    return () => {
      if (layoutId) removeItem(layoutId, { componentId })
    }
  }, [componentId, layoutId, removeItem])

  const sharedMaxFontSize = useMemo(() => {
    if (fontSizes?.every((f) => f.isReady)) {
      return minBy(fontSizes, 'fontSize')?.fontSize
    }

    return Infinity
  }, [fontSizes])

  const upFontSize = useCallback(
    (data: Omit<Partial<IndividualFitTextData>, 'componentId'>) =>
      layoutId ? updateFontSize(layoutId, { ...data, componentId }) : null,
    [componentId, layoutId, updateFontSize]
  )

  return { sharedMaxFontSize, updateFontSize: upFontSize }
}

function upsertItem(
  array: Array<IndividualFitTextData>,
  newItem: Partial<IndividualFitTextData>
): Array<IndividualFitTextData> {
  const index = array.findIndex(
    (item) => item.componentId === newItem.componentId
  )

  if (index !== -1) {
    // Replace the found item by creating a new array
    return [
      ...array.slice(0, index),
      { ...array[index], ...newItem },
      ...array.slice(index + 1),
    ]
  } else {
    // Return a new array with the new item added at the end
    return [...array, newItem] as Array<IndividualFitTextData>
  }
}

function removeItem(
  array: Array<IndividualFitTextData>,
  item: Partial<IndividualFitTextData>
) {
  const index = array.findIndex((i) => i.componentId === item.componentId)

  if (index < 0) return array

  return [...array.slice(0, index), ...array.slice(index + 1)]
}

function extractComputedPaddingValues(css: CSSStyleDeclaration) {
  const { paddingTop, paddingBottom, paddingLeft, paddingRight } = css
  return {
    paddingTop: Number(paddingTop.replace('px', '')),
    paddingBottom: Number(paddingBottom.replace('px', '')),
    paddingLeft: Number(paddingLeft.replace('px', '')),
    paddingRight: Number(paddingRight.replace('px', '')),
  }
}
