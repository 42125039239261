import { useMemo } from 'react'
import { useNow } from '@gaudia/ui-common'
import dayjs from 'dayjs'

// import find from 'lodash/find'
// import _fpSet from 'lodash/fp/set'
// import orderBy from 'lodash/orderBy'
// import { Query } from 'react-query'

import { /*useQueryCache,*/ useRequest } from '../../hooks/queryHooks'

export const useMonitorQueues = ({
  monitorId,
  locationIds,
}: {
  monitorId?: string
  locationIds?: Array<string>
}) => {
  // const queryCache = useQueryCache()

  const now = useNow({ interval: 60 * 1000 })
  const startOfDay = useMemo(
    () => dayjs(now).startOf('day').toDate().toISOString(),
    [now]
  )

  /**
   * Waiting
   */

  // 'locationQueueWaiting',
  const waiting = useRequest(
    [
      'monitorQueueWaiting',
      {
        visits: [
          {
            where: {
              locationId: { IN: locationIds },
              status: { EQ: 'waiting' },
              createdAt: { GTE: startOfDay },
              OR: [{ canVisit: { EQ: true } }, { selfCheckin: { EQ: false } }],
            },
            sort: 'createdAt ASC',
            limit: 20,
          },
          {
            items: {
              id: true,
              ticketCode: true,
              order: true,
              locationId: true,
              location: {
                id: true,
                shortName: true,
              },
              createdAt: true,
              updatedAt: true,
            },
          },
        ],
      },
    ],

    { enabled: !!locationIds?.length }
  )

  /*const waitingCache = useMemo(
    () =>
      queryCache
        .getQueries()
        .find((q) => q.queryKey[0] === 'locationQueueWaiting'),
    [waiting?.data]
  )*/

  /**
   * Visiting
   */

  // locationQueueVisiting
  const visiting = useRequest(
    [
      'monitorQueueVisiting',
      {
        monitorVisitsByLocationId: [
          {
            // where: {
            //   locationId: { IN: locationIds },
            //   status: { EQ: 'started' },
            //   startedAt: { GTE: startOfDay },
            // },
            monitorId,
            /*sort: 'startedAt DESC',
          limit: 1,*/
          },
          {
            location: {
              id: true,
              shortName: true,
            },
            visit: {
              id: true,
              ticketCode: true,
              order: true,
              createdAt: true,
              updatedAt: true,
            },
          },
        ],
      },
    ],
    { enabled: !!locationIds?.length }
  )

  /*const visitingCache = useMemo(
    () =>
      queryCache
        .getQueries()
        .find((q) => q.queryKey[0] === 'locationQueueVisiting'),
    [visiting?.data]
  )*/

  // const upsertInCache = (
  //   visit: Visit,
  //   cache: Query<unknown, unknown>,
  //   orderByCriteria?: [string, 'asc' | 'desc']
  // ) => {
  //   const items: Array<{ location: Location; visit: Visit }> =
  //     cache.state.data.data.monitorVisitsByLocationId
  //
  //   const existingItem = find(cache.state.data.data.visits.items, {
  //     id: visit?.id,
  //   })
  //
  //   let newItems = [...items]
  //
  //   if (existingItem && existingItem?.updatedAt) {
  //     const itemIndex =
  //       cache?.state?.data?.data?.visits?.items?.indexOf(existingItem)
  //
  //     const existingDate = existingItem?.updatedAt
  //       ? new Date(existingItem?.updatedAt)
  //       : 0
  //     const newDate = visit?.updatedAt ? new Date(visit?.updatedAt) : 0
  //
  //     if (newDate > existingDate) {
  //       newItems[itemIndex] = visit
  //     }
  //   } else {
  //     newItems.push(visit)
  //   }
  //
  //   newItems = orderByCriteria
  //     ? orderBy(newItems, orderByCriteria[0], orderByCriteria[1])
  //     : newItems
  //
  //   const updatedData = _fpSet(
  //     'data.visits.items',
  //     newItems,
  //     cache.state.data ?? {}
  //   )
  //
  //   cache.setData(updatedData)
  // }

  /*

  const removeFromCache = (visit: Visit, cache: Query<any, any>) => {
    const items = cache.state.data?.data?.visits?.items ?? []

    const newItems = items.filter((item: Visit) => item?.id !== visit?.id)

    const updatedData = _fpSet(
      'data.visits.items',
      newItems,
      cache.state?.data ?? {}
    )

    cache.setData(updatedData)
  }*/

  return { waiting, visiting }
}
