import React from 'react'
import { initLogger } from '@gaudia/ui-common'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

const isProd = import.meta.env.PROD
const environment = import.meta.env.VITE_ENV
const logger = initLogger('Sentry')

export const initSentry = () => {
  if (!isProd) {
    logger.warn('App not in production mode, skipping Sentry initialization')
    return
  }

  Sentry.init({
    dsn: 'https://23e469e45b0449f12127a2f0a41e7621@o444161.ingest.sentry.io/4506427295531008',
    environment,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    beforeSend: (event) => {
      const exception = event.exception?.values && event.exception?.values[0]
      if (
        exception?.value?.includes(
          'Object captured as promise rejection with keys'
        )
      ) {
        return null
      }
      return event
    },
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
