import { useCallback, useEffect, useMemo } from 'react'
import { initLogger, Location, useTimeoutCounter } from '@gaudia/ui-common'
import { AnimatePresence } from 'framer-motion'
import { keyBy } from 'lodash'
import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'
import { ChevronLeft } from 'lucide-react'
import { useParams } from 'react-router-dom'

import gaudiaLogo from '@/assets/gaudia-logo.png'
import { FullscreenWrapper } from '@/components/FullscreenWrapper'
import { OfflineWarning } from '@/components/OfflineWarning.tsx'
import { Button } from '@/components/ui/button'
import { useMutation, useRequest } from '@/hooks/queryHooks.ts'
import { useUpdateApp } from '@/hooks/useUpdateApp.ts'
import { useURLState } from '@/hooks/useURLState'
import { useAblyEvent } from '@/lib/ably'
import { cn } from '@/lib/utils'
import { locationGroups as defaultLocationGroups } from '@/pages/CheckIn/data.ts'
import { ChooseMacroGroup } from '@/pages/CheckIn/steps/ChooseMacroGroup.tsx'
import { ChooseMicroService } from '@/pages/CheckIn/steps/ChooseMicroService.tsx'
import { PrintingTicket } from '@/pages/CheckIn/steps/PrintingTicket.tsx'
import { SpecialNeeds } from '@/pages/CheckIn/steps/SpecialNeeds.tsx'
import { LocationGroup, MicroService } from '@/pages/CheckIn/types.ts'

const DEBUG = false

const stages = [
  'chooseMacroGroup',
  'chooseMicroService',
  'specialNeeds',
  'printingTicket',
] as const

export type Stage = (typeof stages)[number]

const stagesConfig: Record<Stage, { backBtnVisible?: boolean }> = {
  chooseMacroGroup: { backBtnVisible: false },
  chooseMicroService: { backBtnVisible: true },
  specialNeeds: { backBtnVisible: true },
  printingTicket: { backBtnVisible: false },
}

export interface State {
  stage?: Stage
  selectedLocationGroupIndex?: string
  selectedLocationId?: string
  selectedMicroServiceName?: string
  specialNeeds?: string
}

export const CheckIn = () => {
  const { checkinKioskId } = useParams()
  const logger = useMemo(
    () => initLogger('checkinKiosk', { checkinKioskId }),
    [checkinKioskId]
  )
  const [state, setState] = useURLState<State>()
  const checkinKioskQuery = useRequest({
    checkinKiosk: [
      { where: { id: { EQ: checkinKioskId } } },
      {
        id: true,
        name: true,
        locations: { id: true, name: true, color: true, extraData: true },
      },
    ],
  })

  const checkinKiosk = checkinKioskQuery.data?.checkinKiosk
  const locations = useMemo(
    () => checkinKiosk?.locations ?? [],
    [checkinKiosk?.locations]
  )
  const locationsHash = useMemo(() => keyBy(locations, 'id'), [locations])
  const locationGroups = useMemo<Array<LocationGroup>>(() => {
    if (!locations?.length) return []

    return defaultLocationGroups.map((locationGroup) => {
      const locations = locationGroup.locationIds.map(
        (id) => locationsHash[id]
      ) as Array<Location>
      const microServices = locationGroup.microServices.map((ms) => {
        const locationId = ms.locationId ?? locationGroup.locationIds?.[0]
        const location = locationsHash[locationId] as Location

        return {
          ...ms,
          locationId,
          location,
        }
      })

      return {
        ...locationGroup,
        locations,
        microServices: sortBy(microServices, ['name']),
      }
    })
  }, [locations?.length, locationsHash])

  // const previousStage = usePrevious(state.stage)

  useEffect(() => {
    if (!state.stage || !stages.includes(state.stage)) {
      setState('stage', 'chooseMacroGroup')
    }

    // if(state.stage === 'chooseMicroService' && !selectedLocationGroup?.microServices?.length) {
    //   const currentStageIndex = stages.findIndex(stage => stage === state.stage)
    //   const previousStageIndex = stages.findIndex(stage => stage === previousStage)
    //   setState('stage', previousStageIndex > currentStageIndex ? '')
    // }
  }, [state.stage])

  const selectedLocationGroup = state.selectedLocationGroupIndex
    ? locationGroups?.[Number(state.selectedLocationGroupIndex)]
    : null

  //////////////////////
  // Queue
  //////////////////////

  const enqueueMutation = useMutation()
  const sendToPrintMutation = useMutation()
  const addToQueue = useCallback(async () => {
    const result = await enqueueMutation.mutate({
      enqueue: [
        {
          data: {
            checkinKioskId,
            locationId: state.selectedLocationId,
            generateTicketNumber: true,
            visitorData: {},
            visitData: {
              tags: compact([
                state.selectedMicroServiceName && {
                  text: state.selectedMicroServiceName,
                  color: 'processing',
                },
                state.specialNeeds && {
                  text: state.specialNeeds,
                  color: 'warning',
                },
              ]),
            },
          },
        },
        {
          id: true,
          ticketCode: true,
          location: { name: true, shortName: true },
        },
      ],
    })

    await sendToPrintMutation.mutate({
      sendTicketToPrint: [
        { visitId: result?.data?.enqueue?.id as string },
        { success: true },
      ],
    })
  }, [
    checkinKioskId,
    enqueueMutation,
    sendToPrintMutation,
    state.selectedLocationId,
    state.selectedMicroServiceName,
    state.specialNeeds,
  ])

  useEffect(() => {
    if (state.stage === 'printingTicket') {
      addToQueue()
    }
  }, [state.stage])

  //////////////////////
  // Event Handlers
  //////////////////////
  const handleMacroGroupClick = useCallback(
    (groupIndex: number) => {
      const locationGroup = locationGroups[groupIndex]
      const hasServices = locationGroup?.microServices?.length > 0
      setState('selectedLocationGroupIndex', String(groupIndex))
      setState('stage', hasServices ? 'chooseMicroService' : 'specialNeeds')
    },
    [locationGroups, setState]
  )

  const handleMicroServiceClick = useCallback(
    (microService: MicroService) => {
      setState('selectedMicroServiceName', microService.name)
      setState('selectedLocationId', microService.locationId)
      setState('stage', 'specialNeeds')
    },
    [setState]
  )

  const handleAssistanceSelection = useCallback(
    (specialNeeds: string | null) => {
      if (specialNeeds) {
        setState('specialNeeds', specialNeeds)
      }
      setState('stage', 'printingTicket')
    },
    [setState]
  )

  const handleGoBack = useCallback(() => {
    const stage = state.stage
    if (!stage) return setState()

    const stageIndex = stages.indexOf(stage)
    const newStageIndex = stageIndex > 0 ? stageIndex - 1 : 0

    setState('stage', stages[newStageIndex])
  }, [setState, state.stage])

  //////////////////////
  // Timeout Handling
  //////////////////////
  const timeoutCounter = useTimeoutCounter(() => setState())
  useEffect(() => {
    const stageIndex = state.stage ? stages.indexOf(state.stage) : 0
    if (!state.stage || stageIndex === 0) {
      return timeoutCounter.clear()
    }
    if (stageIndex === stages.length - 1) {
      return timeoutCounter.enable({
        seconds: 10,
        postponeOnActivity: false,
      })
    }
    if (stageIndex > 0) {
      return timeoutCounter.enable({
        seconds: 60,
        postponeOnActivity: true,
      })
    }
  }, [state.stage])

  const percentTimeElapsed = useMemo(() => {
    if (!timeoutCounter.isActive || !timeoutCounter.options?.seconds)
      return null

    return (
      100 - (timeoutCounter.secondsLeft / timeoutCounter.options?.seconds) * 100
    )
  }, [
    timeoutCounter.isActive,
    timeoutCounter.options?.seconds,
    timeoutCounter.secondsLeft,
  ])

  //////////////////////
  // Realtime events
  //////////////////////
  useAblyEvent(
    [{ channelName: `checkinKiosk:${checkinKiosk?.id}:refresh` }],
    useCallback(() => {
      logger.info(
        `Received checkinKiosk:${checkinKioskId}:refresh ably message. Refreshing`
      )
      window.location.reload()
    }, [])
  )

  //////////////////////
  // App Update
  //////////////////////
  useUpdateApp({ couldUpdate: state.stage === 'chooseMacroGroup' })

  return (
    <FullscreenWrapper className="relative flex flex-col overflow-hidden p-6">
      {DEBUG && <pre>{JSON.stringify({ state }, null, 2)}</pre>}

      <img
        className="absolute right-10 top-10 z-10 w-40"
        src={gaudiaLogo}
        alt="logo"
      />

      <div className="mx-auto flex w-full max-w-5xl grow flex-col">
        <div className="flex grow flex-col">
          {/*//////////////////////*/}
          {/*// Choose Macro Service*/}
          {/*//////////////////////*/}
          <div className="flex grow flex-col space-y-2">
            <AnimatePresence exitBeforeEnter>
              {state.stage === 'chooseMacroGroup' && (
                <ChooseMacroGroup
                  locationGroups={locationGroups}
                  onSelection={(groupIndex) =>
                    handleMacroGroupClick(groupIndex)
                  }
                />
              )}

              {/*//////////////////////*/}
              {/*// Choose Micro Service*/}
              {/*//////////////////////*/}
              {state.stage === 'chooseMicroService' && (
                <ChooseMicroService
                  selectedLocationGroup={selectedLocationGroup}
                  onSelection={(microService) =>
                    handleMicroServiceClick(microService)
                  }
                />
              )}

              {/*//////////////////////*/}
              {/*// Ask if assistance is needed*/}
              {/*//////////////////////*/}
              {state.stage === 'specialNeeds' && (
                <SpecialNeeds
                  selectedLocationGroup={selectedLocationGroup}
                  onSelection={handleAssistanceSelection}
                />
              )}

              {/*//////////////////////*/}
              {/*// Display confirmation and print ticket*/}
              {/*//////////////////////*/}
              {state.stage === 'printingTicket' && (
                <PrintingTicket
                  selectedLocationGroup={selectedLocationGroup}
                  enqueueMutation={enqueueMutation}
                  onPress={() => setState()}
                />
              )}
            </AnimatePresence>

            <div
              className={cn(
                'mt-10 hidden',
                state.stage &&
                  stagesConfig[state.stage].backBtnVisible &&
                  'block'
              )}
            >
              <div>
                <Button
                  className="mb-10 h-auto bg-gradient-to-r from-orange-500 to-red-500 px-4 py-6 text-xl hover:bg-indigo-500 hover:from-red-700 hover:to-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl"
                  onClick={handleGoBack}
                >
                  <ChevronLeft className="mr-1" size={36} /> Torna indietro
                </Button>
              </div>
            </div>

            {timeoutCounter.isActive && (
              <div className="relative h-2.5 rounded-full bg-gray-200 opacity-80">
                <div className=" h-2.5 w-[100%] rounded-full bg-gradient-to-r from-red-500 from-10% via-sky-500 via-80% to-blue-500 opacity-80"></div>
                <div
                  className="absolute right-0 top-0 z-10 h-2.5 rounded-r-full bg-gray-200 transition-all duration-1000 ease-linear"
                  style={{ width: `${percentTimeElapsed}%` }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <OfflineWarning />
    </FullscreenWrapper>
  )
}
