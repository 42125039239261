import { LocationGroup } from '@/pages/CheckIn/types.ts'

export const locationGroups: Array<LocationGroup> = [
  {
    name: 'Anagrafe',
    locationIds: ['6579c475034e24ad33e47185'],
    microServices: [
      {
        name: 'Accesso agli Atti',
      },
      {
        name: 'ANPR',
      },
      {
        name: 'Allineamento CF',
      },
      {
        name: 'Certificati Congruità Anagrafica',
      },
      {
        name: 'Rimpatrio AIRE',
      },
      {
        name: 'Atti Notori',
      },
      {
        name: 'Certificazioni anagrafiche',
      },
      {
        name: 'Convivenza di Fatto',
      },
      {
        name: 'Immigrazione',
      },
      {
        name: 'Irreperibilità',
      },
      {
        name: 'Cambio di Residenza',
      },
      {
        name: 'Rettifica Anagrafica',
      },
      {
        name: 'Rinnovo Stabile Dimora',
      },
      {
        name: 'Senza Fissa Dimora',
      },
      {
        name: 'Soggiorno Permanente',
      },
    ],
  },
  {
    name: 'Stato Civile',
    locationIds: ['6579c213034e24ad33e4705b', '6599762eab255799a680c800'],
    microServices: [
      // Stato Civile A
      // {
      //   name: 'Atto Matrimonio',
      //   locationId: '6579c213034e24ad33e4705b',
      // },
      {
        name: 'Atto Morte',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'Certificati Congruità',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'Copie Integrali',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'DAT',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'Divorzi',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'Estratto Matrimonio',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'Estratto Morte',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'Estratto Nascita',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'Pubblicazioni di Matrimonio',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'Separazioni',
        locationId: '6579c213034e24ad33e4705b',
      },
      {
        name: 'Unioni Civili',
        locationId: '6579c213034e24ad33e4705b',
      },

      // Stato Civile B
      {
        name: 'Adozione',
        locationId: '6599762eab255799a680c800',
      },
      {
        name: 'Atto Nascita',
        locationId: '6599762eab255799a680c800',
      },
      {
        name: 'Cambio Cognome',
        locationId: '6599762eab255799a680c800',
      },
      {
        name: 'Cambio Nome',
        locationId: '6599762eab255799a680c800',
      },
      {
        name: 'Giuramenti',
        locationId: '6599762eab255799a680c800',
      },
      {
        name: 'Jure Sanguinis',
        locationId: '6599762eab255799a680c800',
      },
      {
        name: 'Riconoscimenti',
        locationId: '6599762eab255799a680c800',
      },
    ],
  },

  {
    name: "Carta d'identità",
    locationIds: ['6579c1db034e24ad33e46fb8'],
    microServices: [
      {
        name: 'Ritiro Carta Identità',
      },
      {
        name: 'Segnalazione Errori',
      },
      {
        name: 'Nuova Carta Identità',
      },
    ],
    additionalTags: ['Urgenza Viaggio'],
  },

  {
    name: 'Ufficio Elettorale',
    locationIds: ['6579c493034e24ad33e471d3'],
    microServices: [
      {
        name: 'Iscrizione Liste Elettorali',
      },
      {
        name: 'Iscrizioni Albi',
        content: `Iscrizioni Albi:

• Presidenti di Seggio
• Scrutatori
• Giudici Popolari
• Rinnovo Tessera Elettorale`,
      },
      {
        name: 'Rinnovo Tessere Elettorali',
      },
    ],
  },
]
