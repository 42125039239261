import React from 'react'

import { cn } from '../lib/cn.ts'

export const FullscreenWrapper: React.FC<{
  children?: React.ReactNode
  className?: string
}> = ({ children, className }) => (
  <div className="flex min-h-full min-w-full flex-col items-center justify-center gap-y-10">
    <div className={cn('flex w-full flex-grow flex-col', className)}>
      {children}
    </div>
  </div>
)
