import { createQueryHooks } from '@gaudia/ui-common'

import customClient from '../customClient'

// @ts-ignore
const hooks = createQueryHooks(customClient)

const useRequest = hooks.useRequest
const useMutation = hooks.useMutation2
const useQueryCache = hooks.useQueryCache
const queryCache = hooks.queryCache
const ReactQueryCacheProvider = hooks.ReactQueryCacheProvider

export {
  useRequest,
  useMutation,
  useQueryCache,
  queryCache,
  ReactQueryCacheProvider,
}
