import { useCallback, useMemo, useState } from 'react'
import { initLogger, useNow, useTextToSpeech, Visit } from '@gaudia/ui-common'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import orderBy from 'lodash/orderBy'
import { useParams } from 'react-router-dom'

// import { BigText } from '@/components/BigText'
import gaudiaLogo from '@/assets/gaudia-logo.png'
import { FitText } from '@/components/FitText.tsx'
import { FullscreenWrapper } from '@/components/FullscreenWrapper'
import { OfflineWarning } from '@/components/OfflineWarning'
import { Separator } from '@/components/ui/separator'
import customClient from '@/customClient.ts'
import { useRequest } from '@/hooks/queryHooks.ts'
import { useUpdateApp } from '@/hooks/useUpdateApp.ts'
import { useAbly, useAblyEvent } from '@/lib/ably'
import { useMonitorQueues } from '@/pages/Monitor/useMonitorQueues'

import './Monitor.css'

import { Arrow } from '@/pages/Monitor/Arrow.tsx'

const idsHavingArrowLeft = [
  '6579c475034e24ad33e47185',
  '6599762eab255799a680c800',
]
const idsHavingArrowRight = [
  '6579c213034e24ad33e4705b',
  '6579c1db034e24ad33e46fb8',
]

export const Monitor = () => {
  const { monitorId } = useParams()
  const logger = useMemo(
    () => initLogger('monitor', { monitorId }, { browser: { asObject: true } }),
    [monitorId]
  )
  const textToSpeech = useTextToSpeech({
    namespace: 'monitor',
    customClient,
    textToSpeechOptions: { useCacheDb: true },
  })

  const monitorQuery = useRequest({
    monitorById: [
      { id: monitorId },
      {
        id: true,
        name: true,
        locationIds: true,
        locations: { id: true, name: true, extraData: true },
      },
    ],
  })

  const monitor = monitorQuery.data?.monitorById
  const locations = monitor?.locations
  const locationIds = monitor?.locationIds

  const monitorQueuesQueries = useMonitorQueues({
    monitorId,
    locationIds: locationIds as Array<string>,
  })

  const visitingQueueVisits = useMemo(
    () =>
      orderBy(monitorQueuesQueries.visiting?.data?.monitorVisitsByLocationId, [
        (data) => {
          return locations?.findIndex((l) => l!.id === data?.location?.id)
        },
      ]),
    [locations, monitorQueuesQueries.visiting?.data?.monitorVisitsByLocationId]
  )

  /*const visitingQueueVisitsLength = useMemo(
    () => visitingQueueVisits?.filter((i) => !!i.visit).length ?? 0,
    [visitingQueueVisits]
  )*/
  const waitingQueue = monitorQueuesQueries.waiting?.data?.visits?.items

  //////////////////////
  // Realtime events
  //////////////////////
  useAblyEvent(
    [{ channelName: `monitor:${monitorId}:refresh` }],
    useCallback(() => {
      logger.info(
        `Received monitor:${monitorId}:refresh ably message. Refreshing`
      )
      window.location.reload()
    }, [])
  )

  const handleAblyOnRecover = useCallback(() => {
    ablyEvent.ensureRefetch(monitorQueuesQueries.waiting)
    ablyEvent.ensureRefetch(monitorQueuesQueries.visiting)
  }, [])

  const [lastMessageTimestamp, setLastMessageTimestamp] = useState<Date | null>(
    new Date()
  )

  const ablyEvent = useAblyEvent<{ visit: Visit }>(
    monitor?.id
      ? [
          {
            channelName: `monitor:${monitor?.id}`,
          },
        ]
      : [],
    useCallback((message) => {
      const visit = message.data?.visit
      ablyEvent.ensureRefetch(monitorQueuesQueries.waiting)
      ablyEvent.ensureRefetch(monitorQueuesQueries.visiting)
      setLastMessageTimestamp(new Date())

      if (visit?.status === 'started') {
        const box = visit?.startedBy?.shortName
          ? `, recarsi allo sportello, <emphasis level="moderate">${visit?.startedBy?.shortName}</emphasis>`
          : ''
        const ssml = `<speak>Numero, <emphasis level="moderate">${visit?.location?.shortName}, ${visit?.ticketCode}</emphasis>${box}</speak>`
        textToSpeech.play(ssml)
      }
    }, []),
    { onRecover: handleAblyOnRecover }
  )

  //////////////////////
  // App Update
  //////////////////////
  const ably = useAbly()
  const now = useNow()
  const lastMessageElapsedSeconds = useMemo(
    () => dayjs(now).diff(lastMessageTimestamp, 'seconds'),
    [lastMessageTimestamp, now]
  )
  useUpdateApp({
    couldUpdate: textToSpeech.isQueueIdle && lastMessageElapsedSeconds > 30,
    beforeUpdate: ably.disconnect,
  })

  return (
    <FullscreenWrapper className="relative flex h-screen w-screen flex-col p-6">
      <img
        className="absolute left-10 top-10 z-10 w-40"
        src={gaudiaLogo}
        alt="logo"
      />

      <div className="flex h-full grow flex-col">
        <div className="flex h-full grow flex-col">
          {/*Title*/}
          <div className="mb-2 max-h-28 grow-0 text-center">
            <FitText
              className="font-bold"
              text={locations?.map((l) => l!.name).join(' - ')}
            />
          </div>

          {/*Main Wrapper*/}
          <div className="flex grow space-x-4 overflow-hidden">
            {/*Big numbers container*/}
            <div className="flex h-full min-w-[1px] grow">
              {visitingQueueVisits?.map((i) => (
                <div
                  key={i?.location?.id as string}
                  className="flex min-w-[1px] grow flex-col items-center justify-center px-16 font-extrabold leading-none"
                  style={{
                    fontSize: `${35 / visitingQueueVisits?.length}rem`,
                    width: `${100 / visitingQueueVisits?.length}%`,
                  }}
                >
                  <FitText
                    id="ticketNumbers"
                    text={`${i!.location?.shortName} ${
                      i!.visit?.ticketCode ?? '00'
                    }`}
                  />

                  {idsHavingArrowLeft.includes(i?.location?.id as string) && (
                    <Arrow direction="left" />
                  )}
                  {idsHavingArrowRight.includes(i?.location?.id as string) && (
                    <Arrow direction="right" />
                  )}
                </div>
              ))}
            </div>

            <Separator orientation="vertical" className="bg-gray-400" />

            {/*Waiting visits*/}
            <div className="blur-container relative min-w-[1px] px-2">
              <div className="whitespace-nowrap text-[3rem] font-bold">
                In Attesa
              </div>
              <AnimatePresence>
                {waitingQueue?.map((visit) => (
                  <motion.div
                    className="flex grow !text-[5rem] font-extrabold transition"
                    key={visit?.id}
                    style={
                      {
                        //position: isPresent ? "static" : "absolute"
                      }
                    }
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0, opacity: 0, position: 'absolute' }}
                  >
                    {visit!.location?.shortName} {visit!.ticketCode}
                  </motion.div>
                ))}
              </AnimatePresence>
              {!waitingQueue?.length && (
                <p className="text-center text-[2rem]">Nessuno</p>
              )}
            </div>
          </div>
          {/*<pre>{JSON.stringify(monitorQueuesQueries, null, 2)}</pre>*/}
        </div>
      </div>

      <OfflineWarning />
    </FullscreenWrapper>
  )
}
