import { useEffect } from 'react'
import { initLogger, useTimeoutCounter } from '@gaudia/ui-common'
import * as Sentry from '@sentry/react'
import { useRouteError } from 'react-router-dom'

import { FullscreenWrapper } from '@/components/FullscreenWrapper.tsx'

const logger = initLogger('ErrorBoundaryElement')

export const ErrorBoundaryElement = () => {
  const error = useRouteError()
  const timer = useTimeoutCounter(() => {
    logger.info('Refreshing')
    window.location.reload()
  })

  useEffect(() => {
    Sentry.captureException(error)
    timer.enable({ seconds: 3 })
  }, [])

  return (
    <FullscreenWrapper>
      <div className="relative flex min-h-screen w-screen overflow-hidden">
        <div className="absolute inset-x-0 top-0 z-0 overflow-hidden pl-[50%]">
          <img
            src="/images/beams-home@95.jpeg"
            alt=""
            className="ml-[-39rem] w-[113.125rem] max-w-none"
          />
        </div>
        <main className="relative z-10 flex flex-1 flex-col items-center justify-center">
          <div>Qualcosa é andato storto</div>
          <div>Il sistema verrá riavviato in {timer.secondsLeft} secondi</div>
        </main>
      </div>
    </FullscreenWrapper>
  )
}
