import { useEffect } from 'react'
import { initLogger } from '@gaudia/ui-common'
import { setUser } from '@sentry/react'
import LogRocket from 'logrocket'

import { useRequest } from './queryHooks'

const logger = initLogger('identity', {}, { browser: { asObject: true } })

export const useIdentity = () => {
  const identityQuery = useRequest(
    {
      identity: {
        __typename: true,
        on_Monitor: {
          id: true,
          name: true,
        },
        on_CheckinKiosk: {
          id: true,
          name: true,
        },
        on_User: {
          id: true,
          firstname: true,
          lastname: true,
          email: true,
        },
      },
    },
    { refetchOnWindowFocus: false, cacheTime: Infinity, staleTime: Infinity }
  )

  const identity = identityQuery.data?.identity

  useEffect(() => {
    let username = null
    let traits = {}

    if (identity?.__typename === 'User') {
      const fullName = `${identity.firstname} ${identity.lastname}`
      username = `${identity.email}-${identity.id}`

      traits = {
        name: fullName,
        email: identity.email,
      }
    }

    if (identity?.__typename === 'Monitor') {
      const name = identity.name
      username = `monitor-${identity.id}`

      traits = {
        name,
      }
    }

    if (identity?.__typename === 'CheckinKiosk') {
      const name = identity.name
      username = `checkinKiosk-${identity.id}`

      traits = {
        name,
      }
    }

    if (username) {
      logger.info({ username, traits }, 'setting traits')
      LogRocket.identify(username, traits)
      if (identity?.id) {
        setUser({ id: identity?.id, username: username })
      }

      /*

      // @ts-ignore
      const gtag = window.gtag
      gtag('config', process.env.REACT_APP_GTAG, {
        user_id: username,
      })*!/*/
    }
  }, [identity])

  return identityQuery
}
