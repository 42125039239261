import React from 'react'
import { motion } from 'framer-motion'

import { BigText } from '@/components/BigText.tsx'
import { cn } from '@/lib/utils.ts'
import { SelectionButton } from '@/pages/CheckIn/SelectionButton.tsx'
import { LocationGroup, MicroService } from '@/pages/CheckIn/types.ts'

const MicroServiceItems: React.FC<{
  items?: Array<string>
  className?: string
  showParens?: boolean
}> = ({ items, showParens, className }) => {
  if (items?.length) {
    return (
      <span
        className={cn(
          'whitespace-normal text-left text-lg md:text-xl lg:text-3xl xl:text-5xl 2xl:text-6xl',
          className
        )}
      >
        {!!showParens && '('}
        {items.map((item, index) => {
          const isLast = index === items!.length! - 1

          return (
            <span>
              {item}
              {isLast ? '' : ', '}
            </span>
          )
        })}
        {!!showParens && ')'}
      </span>
    )
  }

  return null
}

export const ChooseMicroService: React.FC<{
  selectedLocationGroup?: LocationGroup | null
  onSelection: (microService: MicroService) => void
}> = ({ selectedLocationGroup, onSelection }) => (
  <motion.div
    key="chooseMicroService"
    className="flex grow flex-col"
    initial={{ opacity: 0, translateY: '-1%' }}
    animate={{ opacity: 1, translateY: 0 }}
    exit={{ opacity: 0, translateY: '1%' }}
    transition={{ duration: 0.3 }}
  >
    <BigText className="mb-8 text-justify">
      Buongiorno. <br />
      Prego selezionare il servizio
    </BigText>

    <div className="flex grow flex-col">
      <div
        className={cn(
          'flex grow flex-col space-y-16',
          (selectedLocationGroup?.microServices?.length ?? 0) > 4 && 'space-y-2'
        )}
      >
        {selectedLocationGroup?.microServices?.map(
          (microService: MicroService) => (
            <SelectionButton
              key={microService.name}
              className="max-h-72 border text-black"
              style={{ background: microService.location?.color as string }}
              onClick={() => onSelection(microService)}
            >
              <div className="text-2xl whitespace-pre leading-3 font-bold uppercase sm:text-3xl md:text-4xl lg:text-4xl xl:text-6xl 2xl:text-7xl text-left">
                {microService.content ?? microService.name}
              </div>
              <MicroServiceItems
                items={microService.items}
                className="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
              />
            </SelectionButton>
          )
        )}
      </div>
    </div>
  </motion.div>
)
