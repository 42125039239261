import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useURLState = <S extends object>() => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )
  const state = useMemo<S>(
    () => Object.fromEntries(urlSearchParams.entries()) as S,
    [urlSearchParams]
  )

  const setState = useCallback(
    <K extends keyof S>(key?: K, value?: S[K]) => {
      if (!key) {
        return navigate({
          pathname: location.pathname,
          search: '',
        })
      }

      if (value === null || typeof value === 'undefined') {
        urlSearchParams.delete(String(key))
      } else {
        urlSearchParams.set(String(key), String(value))
      }

      navigate({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
      })
    },
    [location.pathname, navigate, urlSearchParams]
  )

  return [state, setState] as const
}
