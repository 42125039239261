import React from 'react'
import ReactDOM from 'react-dom'

import App from '@/App'
import { initLogrocket } from '@/lib/initLogrocket.ts'
import { initSentry } from '@/lib/initSentry.ts'
import { initServiceWorker } from '@/lib/initServiceWorker'

import './index.css'

initLogrocket()
initSentry()
initServiceWorker()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
)
