import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useIdentity } from '../hooks/useIdentity'
import { useToken } from '../hooks/useToken'

export function Redirector() {
  const location = useLocation()
  const navigate = useNavigate()
  const { token } = useToken({ locationSearch: location?.search })

  const identityQuery = useIdentity()

  useEffect(() => {
    if (token && !identityQuery.isLoading) {
      const interval = setInterval(() => {
        if (!identityQuery.isLoading) {
          identityQuery.refetch()
          clearInterval(interval)
        }
      }, 200)

      return () => clearInterval(interval)
    }
  }, [token, identityQuery.isLoading])

  useEffect(() => {
    const id = identityQuery.data?.identity?.id

    if (identityQuery.data?.identity?.__typename === 'CheckinKiosk') {
      navigate({ pathname: `/checkin-kiosk/${id}` })
    }

    if (identityQuery.data?.identity?.__typename === 'Monitor') {
      navigate({ pathname: `/monitor/${id}` })
    }
  }, [
    navigate,
    identityQuery.data?.identity?.__typename,
    identityQuery.data?.identity?.id,
  ])

  return (
    <div className="flex min-h-full min-w-full flex-col items-center justify-center gap-y-10 p-6">
      <div className="max-w-7xl">
        <p className="text-center text-base text-gray-900">
          {identityQuery?.isFetchingInitial ? (
            <>Caricamento in corso...</>
          ) : null}

          {identityQuery?.error ? (
            <div>
              Errore:{' '}
              {
                /* @ts-expect-error variable error type from api */
                identityQuery?.error?.[0]?.message
              }
            </div>
          ) : null}

          {!identityQuery?.loading &&
          !identityQuery.error &&
          identityQuery.data ? (
            <>
              Non é possibile fare redirect automatico per i consumer di tipo "
              {identityQuery?.data?.identity?.__typename}"
            </>
          ) : null}
        </p>
      </div>
    </div>
  )

  // return <div>ciao</div>
}
