import React from 'react'
import { motion } from 'framer-motion'

import { BigText } from '@/components/BigText.tsx'
import { SelectionButton } from '@/pages/CheckIn/SelectionButton.tsx'
import { LocationGroup } from '@/pages/CheckIn/types.ts'

export const SpecialNeeds: React.FC<{
  selectedLocationGroup?: LocationGroup | null
  onSelection: (category: string | null) => void
}> = ({ selectedLocationGroup, onSelection }) => (
  <motion.div
    key="specialNeeds"
    className="flex grow flex-col"
    initial={{ opacity: 0, translateY: '-1%' }}
    animate={{ opacity: 1, translateY: 0 }}
    exit={{ opacity: 0, translateY: '1%' }}
    transition={{ duration: 0.3 }}
  >
    <div className="flex grow flex-col justify-center">
      <BigText className="mb-16 text-justify">
        Appartieni ad una delle seguenti categorie?
      </BigText>
      <div className="flex flex-col justify-center space-y-12">
        <SelectionButton
          className="min-h-[200px] grow-0 font-bold uppercase text-black"
          style={{
            background: selectedLocationGroup?.locations?.[0]?.color as string,
          }}
          onClick={() => onSelection('Prenotato')}
        >
          Con Prenotazione
        </SelectionButton>
        <SelectionButton
          className="min-h-[200px] grow-0 font-bold uppercase text-black"
          style={{
            background: selectedLocationGroup?.locations?.[0]?.color as string,
          }}
          onClick={() => onSelection('Donna in gravidanza')}
        >
          Donna in gravidanza
        </SelectionButton>
        <SelectionButton
          className="min-h-[200px] grow-0 font-bold uppercase text-black"
          style={{
            background: selectedLocationGroup?.locations?.[0]?.color as string,
          }}
          onClick={() => onSelection('Utente con disabilità')}
        >
          Utente con disabilità
        </SelectionButton>
        <SelectionButton
          className="min-h-[200px] grow-0 font-bold uppercase text-black"
          style={{
            background: selectedLocationGroup?.locations?.[0]?.color as string,
          }}
          onClick={() => onSelection('Utente con esigenze speciali')}
        >
          Utente con esigenze speciali
        </SelectionButton>

        {selectedLocationGroup?.additionalTags?.map((tag) => (
          <SelectionButton
            className="min-h-[200px] grow-0 font-bold uppercase text-black"
            style={{
              background: selectedLocationGroup?.locations?.[0]
                ?.color as string,
            }}
            onClick={() => onSelection(tag)}
          >
            {tag}
          </SelectionButton>
        ))}

        <SelectionButton
          className="min-h-[200px] grow-0 font-bold uppercase text-black"
          style={{
            background: selectedLocationGroup?.locations?.[0]?.color as string,
          }}
          onClick={() => onSelection(null)}
        >
          Nessuna delle precedenti
        </SelectionButton>
      </div>
    </div>
  </motion.div>
)
