import { useEffect } from 'react'
import { initLogger } from '@gaudia/ui-common'
import { create } from 'zustand'

const logger = initLogger('App Updater')

interface UpdateAppState {
  hasUpdate: boolean
  setHasUpdate: (hasUpdate: boolean) => void
}

export const useAppUpdateStore = create<UpdateAppState>((set) => ({
  hasUpdate: false,
  setHasUpdate: (hasUpdate: boolean) => set(() => ({ hasUpdate })),
}))

export const useUpdateApp = (
  {
    couldUpdate,
    beforeUpdate,
  }: {
    couldUpdate?: boolean
    beforeUpdate?: (...args: Array<unknown>) => unknown
  } = { couldUpdate: true }
) => {
  const store = useAppUpdateStore()

  useEffect(() => {
    if (couldUpdate && store.hasUpdate) {
      ;(async () => {
        logger.info('Updating App')
        await (async () => beforeUpdate?.())().catch(() => {})
        window.location.reload()
      })()
    }
  }, [beforeUpdate, couldUpdate, store.hasUpdate])
}
