import React from 'react'

import { cn } from '@/lib/utils.ts'

export const BigText: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => (
  <div
    {...props}
    className={cn(
      'text-2xl font-bold !leading-relaxed text-gray-900 sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl',
      props.className
    )}
  />
)
