import { useEffect, useMemo, useState } from 'react'
import { PreMiddlewareFunction } from '@gaudia/ui-common'
import { useLocation, useNavigate } from 'react-router-dom'

import { httpClient } from '../lib/httpClient'

const development = true

export const useToken = (
  {
    locationSearch,
    remove,
    registerMiddleware,
  }: {
    locationSearch: string
    remove?: boolean
    registerMiddleware?: boolean
  } = {
    locationSearch: '',
    remove: true,
    registerMiddleware: false,
  }
) => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useMemo(
    () => new URLSearchParams(locationSearch),
    [locationSearch]
  )
  const queryToken = useMemo(() => urlParams.get('token'), [urlParams])
  const queryOrganizationId = useMemo(
    () => urlParams.get('organizationId'),
    [urlParams]
  )
  const [token, setToken] = useState<string | null>(queryToken)
  const [organizationId, setOrganizationId] = useState<string | null>(
    queryOrganizationId
  )

  useEffect(() => {
    if (queryToken) {
      setToken(() => queryToken)
    }
  }, [queryToken])

  useEffect(() => {
    if (organizationId) {
      setOrganizationId(() => organizationId)
    }
  }, [organizationId])

  useEffect(() => {
    if (token) {
      if (registerMiddleware) {
        const middleware: PreMiddlewareFunction = ({ headers }) => {
          headers.authorization = token
        }
        httpClient.addMiddleware('pre', middleware)
      }

      if (!development && remove) {
        urlParams.delete('token')
      }
      const newSearchParams = urlParams.toString()
      navigate({
        pathname: location.pathname,
        search: newSearchParams ? `?${newSearchParams}` : '',
      })

      return () => {
        // httpClient.removeMiddleware('pre', middleware)
      }
    }
  }, [token])

  useEffect(() => {
    if (organizationId) {
      if (registerMiddleware) {
        const middleware: PreMiddlewareFunction = ({ headers }) => {
          headers['x-gaudia-organizationid'] = organizationId
        }
        httpClient.addMiddleware('pre', middleware)
      }

      if (!development && remove) {
        urlParams.delete('organizationId')
      }
      const newSearchParams = urlParams.toString()
      navigate({
        pathname: location.pathname,
        search: newSearchParams ? `?${newSearchParams}` : '',
      })

      return () => {
        // httpClient.removeMiddleware('pre', middleware)
      }
    }
  }, [organizationId])

  return { token }
}
