import React from 'react'

import { Button } from '@/components/ui/button.tsx'
import { cn } from '@/lib/utils.ts'

export const SelectionButton = (props: React.ComponentProps<typeof Button>) => (
  <Button
    {...props}
    className={cn(
      'max-h-96 grow flex-col items-start justify-around bg-gradient-to-r from-blue-600 to-violet-600 px-10 py-6 text-2xl !leading-relaxed tracking-wide sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl',
      props.className
    )}
  />
)
